import React, { useState, useRef } from 'react';
import './App.css';
import photo from './logo512.png'

function App() {
  const [images, setImages] = useState([]);
  const [titles, setTitles] = useState(['Test Title']);
  const fileInputRef = useRef(null);

  const addTitle = () => {
    setTitles([...titles, 'Test Title']); // Add an empty string as a new title
  };

  // Delete a specific image
  const deleteImage = (index) => {
    console.log(index)
    const updatedImages = images.filter((_, imgIndex) => imgIndex !== index);
    setImages(updatedImages);
  };
  

  const handleClearFiles = () => {
    // Clear the state
    setImages([]);
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleTitleChange = (index, value) => {
    const updatedTitles = [...titles];
    updatedTitles[index] = value; // Update the title at the specific index
    setTitles(updatedTitles);
  };

  const deleteTitle = (index) => {
    setTitles(titles.filter((_, titleIndex) => titleIndex !== index));
  };

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files).filter(
  //     file => file.size <= 2097152 // 2MB in bytes
  //   );

  //   // Now, process these files as needed, e.g., set them in state
  //   setImages(files); // Assuming you have a state setter like this
  // };

  const gcd = (a, b) => {
    return b === 0 ? a : gcd(b, a % b);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    let newErrorMessages = [];
    let validFiles = [];
  
    const checkImage = (file) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          const aspectRatio = width / height;
          const divisor = gcd(width, height);
          const simplifiedWidth = width / divisor;
          const simplifiedHeight = height / divisor;
  
          if (width < 640) {
            newErrorMessages.push(`Image width is too small (${width}px). Minimum 640px required.`);
            reject();
          } else if (width < 1280 || height < 720) {
            newErrorMessages.push(`Recommended minimum image size is 1280x720. Current size: ${width}x${height}`);
            reject();
          } else if (aspectRatio.toFixed(2) !== (16 / 9).toFixed(2)) {
            newErrorMessages.push('Recommended image aspect ratio is 16:9. Current ratio: ' + simplifiedWidth + ':' + simplifiedHeight);
            reject();
          } else {
            resolve(file);
          }
        };
        img.onerror = () => {
          newErrorMessages.push(`Failed to load image: ${file.name}`);
          reject();
        };
      });
    };
  
    const fileChecks = Array.from(event.dataTransfer.files).map(file => {
      if (file.size > 2097152) { // 2MB in bytes
        newErrorMessages.push(`File ${file.name} size exceeds 2MB.`);
        return Promise.reject();
      } else {
        return checkImage(file);
      }
    });
  
    try {
      validFiles = await Promise.all(fileChecks);
    } catch (error) {
      // Errors are already handled within each promise, so this block can remain empty
    }
  
    setErrorMessages(newErrorMessages);
    if (validFiles.length > 0) {
      const newImages = validFiles.map(file => ({
        id: Date.now() + file.name,
        url: URL.createObjectURL(file),
        titles: []
      }));
      setImages(prevImages => [...prevImages, ...newImages]);
    }
  };
  

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    let newErrorMessages = [];
    let validFiles = [];

    files.forEach(file => {
      if (file.size > 2097152) { // 2MB in bytes
        newErrorMessages.push('File size exceeds 2MB');
      } else {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          const aspectRatio = width / height;
          const divisor = gcd(width, height);
          const simplifiedWidth = width / divisor;
          const simplifiedHeight = height / divisor;
          let hasError = false;

          if (width < 640) {
            newErrorMessages.push(`Image width is too small (${width}px). Minimum 640px required.`);
            hasError = true;
          }
          if (width < 1280 || height < 720) {
            newErrorMessages.push(`Recommended minimum image size is 1280x720. Current size: ${width}x${height}`);
            hasError = true;
          }
          if (aspectRatio.toFixed(2) !== (16 / 9).toFixed(2)) {
            newErrorMessages.push('Recommended image aspect ratio is 16:9. Current ratio: ' + simplifiedWidth + ':' + simplifiedHeight);
            hasError = true;
          }

          if (!hasError) {
            validFiles.push({
              id: Date.now() + file.name,
              url: img.src,
              titles: []
            });
          }

          // This check ensures state is updated only after all files are processed
          if (files.length === validFiles.length + newErrorMessages.length) {
            setImages(prevImages => [...prevImages, ...validFiles]);
            setErrorMessages(newErrorMessages);
            console.log(newErrorMessages)
          }
        };
      }
    });
  };


  const Overlay = () => {
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent
        zIndex: 999, // below the popup but above other items
      }}></div>
    );
  };

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const Popup = ({ children, onClose }) => {
    return (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        zIndex: 1000, // to ensure it's above other elements
        border: '1px solid black',
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}>
        {children}
        <button onClick={onClose} style={{ marginTop: '10px' }}>Close</button>
      </div>
    );
  };


  return (
    <div>
      {errorMessages.length > 0 && <Popup onClose={() => setErrorMessages([])}>
        {errorMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Popup>}
      <div className="App-header" style={{ marginBottom: images.length > 0 ? -20 : 0, marginTop: images.length > 0 ? -20 : 0  }}>
        <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '70px' }}>
          <img src={photo} alt="logo" style={{ width: '90px', height: '90px' }} />
          <h1 style={{ alignSelf: 'center', marginLeft: '10px' }}>Clicky Thumbnails</h1>
        </header>
        <div>
          <div className="thumbnail-container" style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: '20px',
            flexDirection: "column"
          }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', padding: 10 }}>
                <div
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                  onClick={() => fileInputRef.current && fileInputRef.current.click()}
                  style={{
                    width: '400px',
                    height: '200px',
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                >
                  Drop files here or click to upload
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    multiple
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                </div>
                {images && images.length > 0 && (
                  <button onClick={() => { setImages([]); }} style={{ cursor: 'pointer' }}>Clear</button>
                )}
              </div>
            {images.map((image, index) => (
              <div key={index} style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
                <img src={image.url} style={{
                  width: '90px',
                  height: '50.5px',
                }} />
                <button onClick={() => deleteImage(index)} style={{ marginLeft: 10, padding: '5px 10px', alignSelf: 'center', backgroundColor: '#ff3333', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                  Delete
                </button>
              </div>
            ))}
            {images.length > 0 && <div className="App" style={{ padding: 5, paddingBottom: 25 }}>
              {titles.map((title, index) => (
                <div key={index}>
                  <input
                    type="text"
                    value={title}
                    style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '4px', marginRight: '10px', width: 300 }}
                    onChange={(e) => handleTitleChange(index, e.target.value)}
                  />
                  <button onClick={() => deleteTitle(index)} style={{ padding: '5px 10px', backgroundColor: '#ff3333', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Delete</button>
                </div>
              ))}
              <button onClick={addTitle} style={{ padding: '5px 10px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Add New Title</button>
            </div>}
          </div>


        </div>

      </div>
      {images.length > 0 &&
        <div id="image-box" style={{ display: 'flex', flexDirection: 'column', flex: 1,        backgroundColor: "#fafafa", paddingBottom: 50 }}>


          <div style={{
            display: 'flex', flexWrap: 'wrap', display: "flex",   
             paddingTop: 10, backgroundColor: "#fafafa",
          }}>
            {/* <h2>Home Page</h2> */}
            {images.map(image => (
              titles.map((title, titleIndex) => (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: "center",
                  marginBottom: '0px',
                  flex: '1 1 auto' // Add flex-grow
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start', // Keep this center for both cases
                    marginBottom: '0px',
                    marginLeft: 10,
                    flex: '1 1 auto'
                  }}>
                    <div style={{ position: 'relative', margin: '0px', marginBottom: '2px', marginTop: '20px', padding: 5 }}>

                      <img
                        src={image.url}
                        style={{
                          width: '360px',
                          height: '202px',
                          margin: '0px',
                          borderRadius: '10px'
                        }}
                      />
                      <div style={{
                        position: 'absolute', backgroundColor: "rgba(0,0,0,.8)", opacity: 1, margin: "10px",
                        paddingRight: '2.5px', paddingLeft: '2.5px',
                        color: "#fff", borderRadius: 4, letterSpacing: ".5px", bottom: 0, right: 0,
                        flexDirection: "row", display: "inline-flex", fontFamily: "Roboto,sans-serif!important",
                        lineHeight: "1.1rem", fontWeight: 500, fontSize: "11px", boxSizing: "border-box", marginBottom: "14px"
                      }}>10:01</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '4px', fontSize: '10px!important', flexDirection: "column" }}>
                      <div style={{ display: 'flex', flexDirection: "row" }}>
                        <div style={{
                          width: "36px",
                          height: "36px",
                          marginRight: "12px",
                          borderRadius: "18px",
                          backgroundColor: "#ddd",
                        }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <p style={{
                            width: '360px',
                            maxWidth: '360px',
                            minWidth: '265px',
                            fontSize: '14px',
                            fontWeight: 500,
                            alignSelf: 'flex-start',
                            textAlign: 'left',
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 4,
                            color: "#0d0d0d"
                          }}>{title}</p>
                          <div style={{

                            display: 'flex', flexDirection: "column",
                            marginLeft: 4,
                            marginTop: 4,
                            alignSelf: 'flex-start',
                            fontFamily: 'Roboto, Arial, sans-serif',
                            fontSize: '12px',
                            fontWeight: 400,
                            textAlign: 'left'
                          }}>
                            <div style={{ color: "#606060" }}>Your channel</div>
                            <div style={{ color: "#606060", marginTop: 2.5 }}>123K views • 1 hour ago</div>
                          </div>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
              ))
            ))}
          </div>
        </div>}
      <div style={{ marginTop:20, marginBottom: 15, textAlign: 'center' }}>
        Clicky Thumbnails (2024)
      </div>

    </div>

  );
}

export default App;
